@use "./common_variables" as *;
@use "./colors" as *;

.btn {
  padding: 12px 16px;
  height: 58px !important;
  align-items: center !important;
  width: 100% !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
}

.btn-sm {
  align-items: center !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  padding: 8px, 16px, 8px, 16px;
}

.button-edit {
  height: 40px;
  padding: 12px 16px;
  align-items: center !important;
  width: 60% !important;
  font-weight: 400;
  outline: 0 transparent solid !important;
  line-height: 14px;
  overflow: hidden !important;
  border-radius: 8px;
}

.btn:focus {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn:active {
  outline: 0 transparent solid !important;
  box-shadow: none !important;
}

.btn:disabled {
  background-color: #dddddd !important;
  color: #f0f0f0;
}

.btn-default-primary {
  background-color: $primary-color !important;
  color: $button-text-color !important;
  text-wrap: nowrap;
}

.btn-default-secondary {
  background-color: transparent !important;
  color: $tertiary-color !important;
  border: solid 1px $tertiary-color !important;
}

.btn-default-secondary:hover {
  filter: brightness(120%);
}

.square-button-default {
  width: 48px;
  padding: 12px 4px;
  height: 48px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid $primary-color;
  color: $primary-color;
  cursor: pointer;
  position: relative;
  overflow: hidden !important;

  &::before,
  &::after {
    font-size: 20px;
    color: $primary-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.square-button-default-x {
  &::before,
  &::after {
    content: "X";
  }
}

.square-button-default-decrement {
  &::before,
  &::after {
    content: "-";
  }
}

.square-button-bg-increment {
  width: 48px;
  padding: 12px 4px;
  height: 48px;
  border-radius: 8px;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  cursor: pointer;
  position: relative;
  overflow: hidden !important;

  &::before,
  &::after {
    content: "+";
    font-size: 20px;
    color: $button-text-color;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.square-button-default:active,
.square-button-bg-increment:active {
  border: 1px solid $button-text-color;
}

.btn-close-default-color {
  background-color: $primary-color !important;
}

@media (max-width: 768px) {
  .square-button-default-x {
    width: 100%;

    &::before,
    &::after {
      content: "X Remover";
      font-size: 16px;
    }
  }

  .square-button-default-decrement {
    width: 100%;

    &::before,
    &::after {
      content: "- Remover";
      font-size: 16px;
    }
  }

  .square-button-bg-increment {
    width: 100%;

    &::before,
    &::after {
      content: "+ Adicionar";
      font-size: 16px;
    }
  }
}

.square-button-default-x:hover {
  filter: brightness(125%);
}

.btn-default-tertiary {
  border: none;
  color: $tertiary-color;
  background: none;
}

.btn-default-tertiary:hover {
  filter: brightness(115%);
  cursor: pointer;
}

.btn-tertiary-danger {
  border: none;
  color: red;
  background: none;
}

.btn-tertiary-danger:hover {
  filter: brightness(170%);
}

.btn-icon {
  border: none;
  background-color: transparent;
}

.modal-header {
  padding: 20px 32px !important;
}

.btn-close-modal {
  border: none !important;
  background: none !important;
  color: $primary-color !important;
}

.bi-pencil-square {
  color: $primary-color !important;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $neutral-level-4 !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: 58px !important;
  padding: 10px 20px !important;
  cursor: pointer !important;
}

.ng-select.ng-select-single.ng-select-container {
  height: 58px !important;
  padding: 10px 20px !important;
  gap: 8px !important;
  border: 1px solid $neutral-level-6 !important;
  border-radius: 8px !important;
  background-color: $neutral-level-8 !important;
  font-weight: 300 !important;
  width: 100% !important;
  color: $neutral-level-4 !important;
}

.ng-select-container::after {
  height: 58px !important;
  padding: 10px 20px !important;
  border: 1px solid $primary-color !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: $neutral-level-8 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: $primary-color !important;
  color: $neutral-level-4 !important;
}

.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected
  .ng-option-label,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked
  .ng-option-label
  .ng-value {
  color: $neutral-level-4 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: $neutral-level-4 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: $neutral-level-3 !important;
  padding: 12px 30px !important;
}

.ng-select .ng-select-container {
  border: 1px solid $neutral-level-6 !important;
  border-radius: 8px !important;
  color: $neutral-level-4 !important;
}

.ng-select .ng-arrow-wrapper {
  color: red !important;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  padding: 12px 30px !important;
}
