@media only screen and (min-width: 320px) and (max-width: 767px) {

    .card-form {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #FDFEFF;

        p-card {
            align-items: center;
            justify-content: center;
            display: flex;
            width: 484px;
            height: 620px;
            top: 154px;
            left: 478px;
            padding: 32px;
            gap: 32px;
            background-color: #FDFEFF;
        }
    }

    .navbar {
        padding-left: 14px !important;
        padding-right: 14px !important;
    }
}