@use './common_variables' as *;

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;

    .logo {
        margin: auto;
        margin-bottom: 32px;
        display: block;
        width: 115.87px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .container {
        .card-form {
            display: flex !important;
            justify-content: center !important;
            flex-direction: column !important;
            align-items: center !important;
            position: absolute !important;
            top: 0 !important;
            bottom: 0 !important;
            left: 0 !important;
            right: 0 !important;
            margin: auto !important;
            background-color: white !important;
            height: 100vh;
        }

        .text-position {
            text-align: center !important;
        }
    }
}