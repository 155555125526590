.h-100 {
    height: 100%;
}

.w-100 {
    width: 100%;
}

.w-120px {
    width: 120px;
}

.w-160px {
    width: 160px;
}

.mh-32px {
    min-height: 32px !important;
}

.mh-85vh {
    min-height: 85vh;
}

.mh-90vh {
    min-height: 90vh;
}

.mh-100vh {
    min-height: 100vh;
}

.h-10vh {
    height: 10vh;
}

.h-20vh {
    height: 20vh;
}

.h-30vh {
    height: 30vh;
}

.h-40vh {
    height: 40vh;
}

.h-50vh {
    height: 50vh;
}

.h-60vh {
    height: 60vh;
}

.h-70vh {
    height: 70vh;
}

.h-80vh {
    height: 80vh;
}

.h-90vh {
    height: 90vh;
}

.h-100vh {
    height: 100vh;
}

.h-2px {
    height: 2px;
}

.h-4px {
    height: 4px;
}

.h-6px {
    height: 6px;
}

.h-8px {
    height: 8px;
}

.h-10px {
    height: 10px;
}

.h-12px {
    height: 12px;
}

.h-14px {
    height: 14px;
}

.h-16px {
    height: 16px;
}

.h-18px {
    height: 18px;
}

.h-20px {
    height: 20px;
}

.h-22px {
    height: 22px;
}

.h-24px {
    height: 24px;
}

.h-26px {
    height: 26px;
}

.h-28px {
    height: 28px;
}

.h-30px {
    height: 30px;
}

.h-32px {
    height: 32px;
}

.h-34px {
    height: 34px;
}

.h-36px {
    height: 36px;
}

.h-38px {
    height: 38px;
}

.h-40px {
    height: 40px;
}

.h-42px {
    height: 42px;
}

.h-44px {
    height: 44px;
}

.h-46px {
    height: 46px;
}

.h-48px {
    height: 48px;
}

.h-50px {
    height: 50px;
}

.w-2px {
    width: 2px;
}

.w-4px {
    width: 4px;
}

.w-6px {
    width: 6px;
}

.w-8px {
    width: 8px;
}

.w-10px {
    width: 10px;
}

.w-12px {
    width: 12px;
}

.w-14px {
    width: 14px;
}

.w-16px {
    width: 16px;
}

.w-18px {
    width: 18px;
}

.w-20px {
    width: 20px;
}

.w-22px {
    width: 22px;
}

.w-24px {
    width: 24px;
}

.w-26px {
    width: 26px;
}

.w-28px {
    width: 28px;
}

.w-30px {
    width: 30px;
}

.w-32px {
    width: 32px;
}

.w-34px {
    width: 34px;
}

.w-36px {
    width: 36px;
}

.w-38px {
    width: 38px;
}

.w-40px {
    width: 40px;
}

.w-42px {
    width: 42px;
}

.w-44px {
    width: 44px;
}

.w-46px {
    width: 46px;
}

.w-48px {
    width: 48px;
}

.w-50px {
    width: 50px;
}

.pt-2px {
    padding-top: 2px;
}

.pt-4px {
    padding-top: 4px;
}

.pt-6px {
    padding-top: 6px;
}

.pt-8px {
    padding-top: 8px;
}

.pt-10px {
    padding-top: 10px;
}

.pt-12px {
    padding-top: 12px;
}

.pt-14px {
    padding-top: 14px;
}

.pt-16px {
    padding-top: 16px;
}

.pt-18px {
    padding-top: 18px;
}

.pt-20px {
    padding-top: 20px;
}

.pt-22px {
    padding-top: 22px;
}

.pt-24px {
    padding-top: 24px;
}

.pt-26px {
    padding-top: 26px;
}

.pt-28px {
    padding-top: 28px;
}

.pt-30px {
    padding-top: 30px;
}

.pt-32px {
    padding-top: 32px;
}

.pt-34px {
    padding-top: 34px;
}

.pt-36px {
    padding-top: 36px;
}

.pt-38px {
    padding-top: 38px;
}

.pt-40px {
    padding-top: 40px;
}

.pt-42px {
    padding-top: 42px;
}

.pt-44px {
    padding-top: 44px;
}

.pt-46px {
    padding-top: 46px;
}

.pt-48px {
    padding-top: 48px;
}

.pt-50px {
    padding-top: 50px;
}

.pt-56px {
    padding-top: 56px;
}

.pt-80px {
    padding-top: 80px;
}

.pt-100px {
    padding-top: 100px;
}

.pt-145px {
    padding-top: 145px;
}

.pr-2px {
    padding-right: 2px;
}

.pr-4px {
    padding-right: 4px;
}

.pr-6px {
    padding-right: 6px;
}

.pr-8px {
    padding-right: 8px;
}

.pr-10px {
    padding-right: 10px;
}

.pr-12px {
    padding-right: 12px;
}

.pr-14px {
    padding-right: 14px;
}

.pr-16px {
    padding-right: 16px;
}

.pr-18px {
    padding-right: 18px;
}

.pr-20px {
    padding-right: 20px;
}

.pr-22px {
    padding-right: 22px;
}

.pr-24px {
    padding-right: 24px;
}

.pr-26px {
    padding-right: 26px;
}

.pr-28px {
    padding-right: 28px;
}

.pr-30px {
    padding-right: 30px;
}

.pr-32px {
    padding-right: 32px;
}

.pr-34px {
    padding-right: 34px;
}

.pr-36px {
    padding-right: 36px;
}

.pr-38px {
    padding-right: 38px;
}

.pr-40px {
    padding-right: 40px;
}

.pr-42px {
    padding-right: 42px;
}

.pr-44px {
    padding-right: 44px;
}

.pr-46px {
    padding-right: 46px;
}

.pr-48px {
    padding-right: 48px;
}

.pr-50px {
    padding-right: 50px;
}

.pb-2px {
    padding-bottom: 2px;
}

.pb-4px {
    padding-bottom: 4px;
}

.pb-6px {
    padding-bottom: 6px;
}

.pb-8px {
    padding-bottom: 8px;
}

.pb-10px {
    padding-bottom: 10px;
}

.pb-12px {
    padding-bottom: 12px;
}

.pb-14px {
    padding-bottom: 14px;
}

.pb-16px {
    padding-bottom: 16px;
}

.pb-18px {
    padding-bottom: 18px;
}

.pb-20px {
    padding-bottom: 20px;
}

.pb-22px {
    padding-bottom: 22px;
}

.pb-24px {
    padding-bottom: 24px;
}

.pb-26px {
    padding-bottom: 26px;
}

.pb-28px {
    padding-bottom: 28px;
}

.pb-30px {
    padding-bottom: 30px;
}

.pb-32px {
    padding-bottom: 32px;
}

.pb-34px {
    padding-bottom: 34px;
}

.pb-36px {
    padding-bottom: 36px;
}

.pb-38px {
    padding-bottom: 38px;
}

.pb-40px {
    padding-bottom: 40px;
}

.pb-42px {
    padding-bottom: 42px;
}

.pb-44px {
    padding-bottom: 44px;
}

.pb-46px {
    padding-bottom: 46px;
}

.pb-48px {
    padding-bottom: 48px;
}

.pb-50px {
    padding-bottom: 50px;
}

.pb-90px {
    padding-bottom: 90px;
}

.pb-40per {
    padding-bottom: 40%;
}

.p-0 {
    padding: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pl-2px {
    padding-left: 2px;
}

.pl-4px {
    padding-left: 4px;
}

.pl-6px {
    padding-left: 6px;
}

.pl-8px {
    padding-left: 8px;
}

.pl-10px {
    padding-left: 10px;
}

.pl-12px {
    padding-left: 12px;
}

.pl-14px {
    padding-left: 14px;
}

.pl-16px {
    padding-left: 16px;
}

.pl-18px {
    padding-left: 18px;
}

.pl-20px {
    padding-left: 20px;
}

.pl-22px {
    padding-left: 22px;
}

.pl-24px {
    padding-left: 24px;
}

.pl-26px {
    padding-left: 26px;
}

.pl-28px {
    padding-left: 28px;
}

.pl-30px {
    padding-left: 30px;
}

.pl-32px {
    padding-left: 32px;
}

.pl-34px {
    padding-left: 34px;
}

.pl-36px {
    padding-left: 36px;
}

.pl-38px {
    padding-left: 38px;
}

.pl-40px {
    padding-left: 40px;
}

.pl-42px {
    padding-left: 42px;
}

.pl-44px {
    padding-left: 44px;
}

.pl-46px {
    padding-left: 46px;
}

.pl-48px {
    padding-left: 48px;
}

.pl-50px {
    padding-left: 50px;
}

.mt-2px {
    margin-top: 2px;
}

.mt-4px {
    margin-top: 4px;
}

.mt-6px {
    margin-top: 6px;
}

.mt-8px {
    margin-top: 8px;
}

.mt-10px {
    margin-top: 10px;
}

.mt-12px {
    margin-top: 12px;
}

.mt-14px {
    margin-top: 14px;
}

.mt-16px {
    margin-top: 16px;
}

.mt-18px {
    margin-top: 18px;
}

.mt-20px {
    margin-top: 20px;
}

.mt-22px {
    margin-top: 22px;
}

.mt-24px {
    margin-top: 24px;
}

.mt-26px {
    margin-top: 26px;
}

.mt-28px {
    margin-top: 28px;
}

.mt-30px {
    margin-top: 30px;
}

.mt-32px {
    margin-top: 32px;
}

.mt-34px {
    margin-top: 34px;
}

.mt-36px {
    margin-top: 36px;
}

.mt-38px {
    margin-top: 38px;
}

.mt-40px {
    margin-top: 40px;
}

.mt-42px {
    margin-top: 42px;
}

.mt-44px {
    margin-top: 44px;
}

.mt-46px {
    margin-top: 46px;
}

.mt-48px {
    margin-top: 48px;
}

.mt-50px {
    margin-top: 50px;
}

.mr-2px {
    margin-right: 2px;
}

.mr-4px {
    margin-right: 4px;
}

.mr-6px {
    margin-right: 6px;
}

.mr-8px {
    margin-right: 8px;
}

.mr-10px {
    margin-right: 10px;
}

.mr-12px {
    margin-right: 12px;
}

.mr-14px {
    margin-right: 14px;
}

.mr-16px {
    margin-right: 16px;
}

.mr-18px {
    margin-right: 18px;
}

.mr-20px {
    margin-right: 20px;
}

.mr-22px {
    margin-right: 22px;
}

.mr-24px {
    margin-right: 24px;
}

.mr-26px {
    margin-right: 26px;
}

.mr-28px {
    margin-right: 28px;
}

.mr-30px {
    margin-right: 30px;
}

.mr-32px {
    margin-right: 32px;
}

.mr-34px {
    margin-right: 34px;
}

.mr-36px {
    margin-right: 36px;
}

.mr-38px {
    margin-right: 38px;
}

.mr-40px {
    margin-right: 40px;
}

.mr-42px {
    margin-right: 42px;
}

.mr-44px {
    margin-right: 44px;
}

.mr-46px {
    margin-right: 46px;
}

.mr-48px {
    margin-right: 48px;
}

.mr-50px {
    margin-right: 50px;
}

.mb-2px {
    margin-bottom: 2px;
}

.mb-4px {
    margin-bottom: 4px;
}

.mb-6px {
    margin-bottom: 6px;
}

.mb-8px {
    margin-bottom: 8px;
}

.mb-10px {
    margin-bottom: 10px;
}

.mb-12px {
    margin-bottom: 12px;
}

.mb-14px {
    margin-bottom: 14px;
}

.mb-16px {
    margin-bottom: 16px;
}

.mb-18px {
    margin-bottom: 18px;
}

.mb-20px {
    margin-bottom: 20px;
}

.mb-22px {
    margin-bottom: 22px;
}

.mb-24px {
    margin-bottom: 24px;
}

.mb-26px {
    margin-bottom: 26px;
}

.mb-28px {
    margin-bottom: 28px;
}

.mb-30px {
    margin-bottom: 30px;
}

.mb-32px {
    margin-bottom: 32px;
}

.mb-34px {
    margin-bottom: 34px;
}

.mb-36px {
    margin-bottom: 36px;
}

.mb-38px {
    margin-bottom: 38px;
}

.mb-40px {
    margin-bottom: 40px;
}

.mb-42px {
    margin-bottom: 42px;
}

.mb-44px {
    margin-bottom: 44px;
}

.mb-46px {
    margin-bottom: 46px;
}

.mb-48px {
    margin-bottom: 48px;
}

.mb-50px {
    margin-bottom: 50px;
}

.ml-2px {
    margin-left: 2px;
}

.ml-4px {
    margin-left: 4px;
}

.ml-6px {
    margin-left: 6px;
}

.ml-8px {
    margin-left: 8px;
}

.ml-10px {
    margin-left: 10px;
}

.ml-12px {
    margin-left: 12px;
}

.ml-14px {
    margin-left: 14px;
}

.ml-16px {
    margin-left: 16px;
}

.ml-18px {
    margin-left: 18px;
}

.ml-20px {
    margin-left: 20px;
}

.ml-22px {
    margin-left: 22px;
}

.ml-24px {
    margin-left: 24px;
}

.ml-26px {
    margin-left: 26px;
}

.ml-28px {
    margin-left: 28px;
}

.ml-30px {
    margin-left: 30px;
}

.ml-32px {
    margin-left: 32px;
}

.ml-34px {
    margin-left: 34px;
}

.ml-36px {
    margin-left: 36px;
}

.ml-38px {
    margin-left: 38px;
}

.ml-40px {
    margin-left: 40px;
}

.ml-42px {
    margin-left: 42px;
}

.ml-44px {
    margin-left: 44px;
}

.ml-46px {
    margin-left: 46px;
}

.ml-48px {
    margin-left: 48px;
}

.ml-50px {
    margin-left: 50px;
}