@use "./colors" as *;
@use "./common_variables" as *;

.default-field {
  height: 58px;
  padding: 12px 30px !important;
  gap: 8px;
  border: 1px solid $neutral-level-6;
  border-radius: 8px;
  background-color: $neutral-level-8 !important;
  font-weight: 300;
  display: block;
  width: 100% !important;
  color: $neutral-level-4 !important;
}

.default-field:focus {
  border: 1px solid $primary-color !important;
  border-radius: 8px;
  display: block;
  outline: none !important;
  box-shadow: none !important;
  background-color: $neutral-level-8;
  color: $neutral-level-4 !important;
}

.default-field::placeholder {
  color: $neutral-level-5 !important;
}

@media (min-width: 768px) {
  .options-sect {
    min-width: 700px;
  }
}

.option-row {
  background-color: #fdfeff;
  color: $neutral-level-4;
  cursor: pointer;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.option-row:hover {
  background-color: #f2f5f8;
}

.password-field {
  -webkit-text-security: disc;
  -moz-text-security: circle;
}

label {
  font-size: 14px;
  color: $neutral-level-3;
}

.upper-case {
  text-transform: capitalize;
}

.fieldSearch {
  padding: 24px;
}

.fieldSearch:focus {
  padding: 24px;
}

.form-select {
  --bs-form-select-bg-img: url('data:image/svg + xml, %3csvgxmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" %3e%3cpathfill="none" stroke="%23343a40" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6" /%3e%3c/svg%3e');
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img)
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 48px !important;
}

.input-group {
  border-color: $primary-color !important;
}

.input-group-text {
  border: 1px solid $neutral-level-6;
  border-radius: 8px;
  height: 58px;
  padding: 24px;
  gap: 8px;
  border: 1px solid $neutral-level-6 !important;
  border-radius: 8px;
  background-color: $neutral-level-8 !important;
  font-weight: 300;
  display: block;
}

input[type="file"] {
  height: 58px;
  gap: 8px;
  border: 1px solid $neutral-level-6;
  border-radius: 8px;
  background-color: $neutral-level-8 !important;
  font-weight: 300;
  display: block;
  width: 100% !important;
  color: $neutral-level-4;
}

input[type="file"]::file-selector-button {
  height: 56px !important;
  margin-right: 20px;
  border: 1px solid $primary-color !important;
  background: transparent !important;
  padding: 5px 20px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: $primary-color !important;
  transition: background 0.3s ease-in;
}

input[type="file"]::file-selector-button:hover {
  filter: brightness(110%) !important;
  cursor: pointer !important;
}

.modal-title {
  color: $neutral-level-3 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 700 !important;
}

.modal-body {
  color: $neutral-level-4 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.5% !important;
  padding: 24px 32px !important;
}

.modal-footer {
  border-top: none !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-capitalize::placeholder {
  text-transform: none;
}

.invalid-field {
  color: red;
  font-size: 1.875em;
}

.form-check-input:checked {
  background-color: $primary-color;
  border-color: $primary-color;
}

.form-switch {
  padding-left: 3em;
}

.flag {
  padding: 12px 16px;
  border: 1px solid #acbdd2;
  background: #f2f5f8;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 8px;
}

.flag-wrapper {
  display: flex;
  flex-direction: column;
}
