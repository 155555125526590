@use "./app/shared/styles" as *;
@import './../node_modules/bootstrap/scss/bootstrap.scss';
// @import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400&display=swap');

* {
    font-family: 'Lexend Deca', sans-serif !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    font-size: 16px;
}

html,
body {
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 600px) {
    .grecaptcha-badge {
        display: none !important;
    }
}