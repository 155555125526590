@use "../styles/colors" as *;

.full-height {
    height: 100vh;
}

.full-height-per {
    height: 100%;
}

.min-full-height {
    min-height: 100vh;
}

.no-scroll-y {
    overflow-y: hidden !important;
}

.no-scroll-x {
    overflow-x: hidden;
}

.cover-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.white-bg {
    background-color: white;
}

.gray-bg {
    background-color: #f5f5f8 !important;
}

.start {
    text-align: start;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.pointer-section {
    cursor: pointer;
}

.limit-x-scroll {
    overflow-x: auto !important;
    white-space: nowrap;
}

.scrollable-col {
    display: inline-flex;
    padding: 14px;
    text-decoration: none;
}

.divisor-line {
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
}

#bottom-modal {
    height: 100vh;
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 0;
    background-color: rgba(49, 49, 49, 0.568);
}

.bottom-modal-clickable-section {
    height: 100%;
}

.bottom-modal {
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 24px;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.soma-card {
    background-color: white;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    margin-top: 16px;
    margin-bottom: 24px;
    -webkit-box-shadow: 5px 5px 15px -3px #000000;
    box-shadow: 5px 5px 15px -3px #000000;
}

.card-content {
    padding: 24px;
    background-color: #f2f5f8;
    width: 100%;
    border-radius: 16px;
}

.card-content-white {
    padding: 16px;
    background-color: #fdfeff;
    border-radius: 8px;
    width: 100%;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.justify-content-space-around {
    display: flex;
    justify-content: space-around;
}

.footer-position {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
}

.footer-separator {
    border-top: 1px solid $neutral-level-6;
}

.section-step {
    position: relative;
    width: 100%;
    padding-bottom: 100px;
    box-sizing: border-box;
    overflow-y: hidden;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    text-align: center;
}